<template>
  <div class="error_bg">
    <img src="../assets/img/errorBg.png" />
    <p>{{ errorMsg }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorMsg: decodeURI(this.$smartStorage.get("errorMsg")),
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.error_bg {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
  text-align: center;
  padding: 1.5rem 0 0 0;
    overflow-y: auto;
  p {
    margin-top: 0.1rem;
    padding: 0 0.4rem;
    width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;
    font-size: .14rem;
  }
}
</style>